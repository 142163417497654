:root {
  --rdp-cell-size: 40px;
  --rdp-background-color: #ffffff;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #3003e1;
  --rdp-accent-color: #3003e1;
  --rdp-background-color-dark: #180270;
  /* Outline border for focused and selected elements */
  @if variable-exists(--main_color-primary) {
    --main_color: var(--main_color-primary);
    --rdp-accent-color: var(--main_color);
    --wp_main-light-primary: rgba(var(--main_color-primary), 0.4);
  } @else {
    --main_color: var(--rdp-accent-color);
    --wp_main-light-primary: rgba(var(--rdp-accent-color), 0.1);
  }
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
  /* Outline border for focused elements */
  --rdp-outline: 1px solid var(--rdp-accent-color);
}

#__next > #signal-be-client {
  .rdp {
    margin: 1em;
  }

  /* Hide elements for devices that are not screen readers */
  //noinspection SpellCheckingInspection
  .rdp-vhidden {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: absolute !important;
    top: 0;
    width: 1px !important;
    height: 1px !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
  }

  /* Buttons */
  .rdp-button_reset {
    appearance: none;
    position: relative;
    margin: 0;
    padding: 0;
    cursor: default;
    color: inherit;
    outline: none;
    background: none;
    font: inherit;

    -moz-appearance: none;
    -webkit-appearance: none;
  }

  .rdp-button {
    border: 2px solid transparent;
  }

  .rdp-button:not(.rdp-day_selected) {
    &.checkin-day {
      border-right-color: #f6f7f9;
      border-bottom-color: #f6f7f9;
      > * {
        z-index: 1;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border: 18px solid transparent;
        border-bottom-color: #f6f7f9;
        border-right-color: #f6f7f9;
      }
    }

    &.checkout-day {
      border-left-color: #f6f7f9;
      border-top-color: #f6f7f9;
      > * {
        z-index: 1;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border: 18px solid transparent;
        border-top-color: #f6f7f9;
        border-left-color: #f6f7f9;
      }
    }
  }

  .rdp-button[disabled] {
    background-color: #f6f7f9;
    > span {
      color: #d8d8d8;
      z-index: 1;
    }
    p {
      color: #d8d8d8;
      z-index: 1;
    }
  }

  .rdp-button:not([disabled]) {
    cursor: pointer;
  }

  .rdp-button:focus:not([disabled]),
  .rdp-button:active:not([disabled]) {
    color: inherit;
    background-color: var(--rdp-background-color);
  }

  .rdp-button:hover:not([disabled]) {
    background-color: var(--wp_main-light-primary);
  }

  .rdp-months {
    display: flex;
  }

  .rdp-month {
    margin: 0 1em;
  }

  .rdp-month:first-child {
    margin-left: 0;
  }

  .rdp-month:last-child {
    margin-right: 0;
  }

  .rdp-table {
    margin: 0;
    max-width: calc(var(--rdp-cell-size) * 7);
    border-collapse: collapse;
  }

  //noinspection SpellCheckingInspection
  .rdp-with_weeknumber .rdp-table {
    max-width: calc(var(--rdp-cell-size) * 8);
    border-collapse: collapse;
  }

  .rdp-caption {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: left;
  }

  .rdp-multiple_months .rdp-caption {
    position: relative;
    display: block;
    text-align: center;
  }

  .rdp-caption_dropdowns {
    position: relative;
    display: inline-flex;
  }

  .rdp-caption_label {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0 0.25em;
    white-space: nowrap;
    color: currentColor;
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-weight: bold;
  }

  .rdp-nav {
    white-space: nowrap;
  }

  .rdp-multiple_months .rdp-caption_start .rdp-nav {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .rdp-multiple_months .rdp-caption_end .rdp-nav {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .rdp-nav_button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    padding: 0.25em;
    border-radius: 100%;
  }

  /* ---------- */
  /* Dropdowns  */
  /* ---------- */

  .rdp-dropdown_year,
  .rdp-dropdown_month {
    position: relative;
    display: inline-flex;
    align-items: center;
  }

  .rdp-dropdown {
    appearance: none;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: inherit;
    opacity: 0;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  .rdp-dropdown[disabled] {
    opacity: unset;
    color: unset;
  }

  .rdp-dropdown:focus:not([disabled]) + .rdp-caption_label,
  .rdp-dropdown:active:not([disabled]) + .rdp-caption_label {
    border: 1px solid var(--main_color);
    border-radius: 6px;
    background-color: var(--rdp-background-color);
  }

  .rdp-dropdown_icon {
    margin: 0 0 0 5px;
  }

  .rdp-head {
    border: 0;
  }

  .rdp-head_row,
  .rdp-row {
    height: 100%;
  }

  .rdp-head_cell {
    vertical-align: middle;
    text-transform: none !important;
    border: 0 !important;
    font-size: 0.75em;
    font-weight: 700;
    text-align: center;
    height: var(--rdp-cell-size);
    padding: 0;
  }

  .rdp-tbody {
    border: 0;
  }

  .rdp-tfoot {
    margin: 0.5em;
  }

  .rdp-cell {
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    border: 0 !important;
    padding: 0;
    text-align: center;
  }

  //noinspection SpellCheckingInspection
  .rdp-weeknumber {
    font-size: 0.75em;
  }

  //noinspection SpellCheckingInspection
  .rdp-weeknumber,
  .rdp-day {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: var(--rdp-cell-size);
    max-width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    margin: 0;
    border: 2px solid transparent;
  }

  .rdp-day_today:not(.rdp-day_outside) {
    font-weight: bold;
  }

  .rdp-day_selected:not([disabled]),
  .rdp-day_selected:focus:not([disabled]),
  .rdp-day_selected:active:not([disabled]),
  .rdp-day_selected:hover:not([disabled]) {
    color: white;
    p {
      color: white;
    }
    background-color: var(--main_color-primary);
  }

  // .rdp-day_selected:focus:not([disabled]) {
  // border: 1px solid black;
  // }

  .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rdp-day_range_end.rdp-day_range_start {
    //border-radius: 100%;
  }

  .rdp-day_range_middle {
    border-radius: 0;
  }
}
