.pagination-container {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
}

.pagination-page {
  color: #868686;
  font-weight: normal;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
    background-color: #f6f7f9;
  }
  &_active {
    color: #333333;
    font-weight: bold;
  }
}
