.desktop-searchbar,
.tablet-searchbar {
  display: none;
}
.mobile-searchbar {
  display: block;
}

@media (min-width: 750px) {
  .desktop-searchbar,
  .mobile-searchbar {
    display: none;
  }
  .tablet-searchbar {
    display: block;
  }
}

@media (min-width: 1000px) {
  .tablet-searchbar,
  .mobile-searchbar {
    display: none;
  }
  .desktop-searchbar {
    display: block;
  }
}
