.apple-pay-button {
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: buy; /* Use any supported button type. */
  height: 40px;
  width: 100%;
  -apple-pay-button-style: black;
}

.apple-pay-button:disabled {
  opacity: 0.5;
}

apple-pay-button {
  --apple-pay-button-width: 140px;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 8px;
  --apple-pay-button-padding: 5px 0px;
}
